import React from "react";
import { Helmet } from "react-helmet";
// api file
import * as Constant from "./Constant";
import Skeleton from "react-loading-skeleton";

class TermsConditions extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: [],
      Loading:true,
    };
  }


  getTestimonial = async () => {
    await fetch(Constant.ShowTerms, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Loading:false,
            terms: data.data
          });
          console.log('terms', this.state.terms)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getTestimonial();
  }

  render() {

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Terms & Conditions - Omega InfoWay</title>
        </Helmet>
        <React.Fragment>
          <section class="ftco-section">
            <div class="container">

              <div class="card card-career info-wrap w-100 pt-3 pb-3 mt-5">
                <div class="row justify-content-center mainheading">
                  <div class="col-md-6 text-center ">
                    <h2 class="heading-section mt-1">Terms & Conditions</h2>
                  </div>
                </div>

              { this.state.Loading === true ? 


               <div className="container">
              
                        <div className="row mt-4">
                          <p style={{ textAlign:'justify' }}> <Skeleton/></p>
                        </div>


                        <div className="row mt-4">
                          <p style={{ textAlign:'justify' }}> <Skeleton count={3}/></p>
                        </div>

                        <div className="row mt-4">
                          <p style={{ textAlign:'justify' }}> <Skeleton count={2}/></p>
                        </div>
                        
                        <div className="row mt-4">
                          <p style={{ textAlign:'justify' }}> <Skeleton count={2}/></p>
                        </div>
                </div>


                :
                <div className="container">
                  {
                    this.state.terms.map((item) => {
                      return (
                        <div className="row mt-4">
                          <p style={{ textAlign:'justify' }}> {item.terms} </p>
                        </div>
                      );
                    })
                  }
                </div>

}
              </div>
            </div>
          </section>
        </React.Fragment>
      </>

    );
  }
}


export default TermsConditions;