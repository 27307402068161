import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import * as Constant from "./Constant";


const Quizz = () => {
  // const history = useHistory();
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);

  const updateTotalScore = (newScore) => {
    setTotalScore(newScore);
  };

  useEffect(() => {
    axios
      .get(Constant.QuizQuestion + '/' + id)
      .then((response) => {
        setQuestions(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion - 1 === null);
      setCurrentQuestion(currentQuestion + 1);

      if (isCorrect) {
        const newScore = totalScore + 1;
        updateTotalScore(newScore);
      } else {
        const newScore = totalScore;
        updateTotalScore(newScore);
      }
      setSelectedAnswer(null);
      setIsCorrect(false);

      const radioButtons = document.querySelectorAll('input[type="radio"]');
      radioButtons.forEach((radioButton) => {
        radioButton.checked = false;
      });

    } else {
      const email = localStorage.getItem("email");
      console.log('email', email);

      const formData = new FormData();
      if (isCorrect) {
        const newScore = totalScore + 1 + '/' + questions.length;
        formData.append("score", newScore);
      } else {
        const newScore = totalScore + '/' + questions.length;
        formData.append("score", newScore);
      }
      // formData.append("score", newScore);
      formData.append("email", email);


      fetch(Constant.QuizeUserUpdate, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            window.location.href = "/quizthankyou";
            // history.push('/quizthankyou');
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error during form submission:", error);
        });
    }

  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Question - Omega InfoWay</title>
      </Helmet>
      <React.Fragment>
        <section class="ftco-section">
          <div class="container quizwidth">
            <div class="card card-career info-wrap pt-3 pb-3 mt-5">
              <h6 className="ms-5 text-dark">Your Total Score is: {totalScore}/{questions.length}</h6>

              {questions.length > 0 && (
                <>
                  <div className="text-center">
                    <h5><div dangerouslySetInnerHTML={{ __html: questions[currentQuestion].question }} /></h5>

                    {questions[currentQuestion].option1 && (
                      <label className="p-2" style={{ width: '265px' }}>
                        <input
                          type="radio"
                          name="options"
                          value={questions[currentQuestion].option1}
                          onChange={() => {
                            if (selectedAnswer === null) {
                              setSelectedAnswer(questions[currentQuestion].option1);
                              setIsCorrect(
                                questions[currentQuestion].option1.toLowerCase() ===
                                questions[currentQuestion].quizanswer.toLowerCase()
                              );
                            }
                          }}
                          disabled={selectedAnswer !== null}
                        />
                        <div dangerouslySetInnerHTML={{ __html: questions[currentQuestion].option1 }} />
                      </label>
                    )}

                    {questions[currentQuestion].option2 && (
                      <label className="p-2" style={{ width: '265px' }}>
                        <input
                          type="radio"
                          name="options"
                          value={questions[currentQuestion].option2}
                          onChange={() => {
                            if (selectedAnswer === null) {
                              setSelectedAnswer(questions[currentQuestion].option2);
                              setIsCorrect(
                                questions[currentQuestion].option2.toLowerCase() ===
                                questions[currentQuestion].quizanswer.toLowerCase()
                              );
                            }
                          }}
                          disabled={selectedAnswer !== null}
                        />
                        <div dangerouslySetInnerHTML={{ __html: questions[currentQuestion].option2 }} />
                      </label>
                    )}
                    <br />
                    {questions[currentQuestion].option3 && (
                      <label className="p-2" style={{ width: '265px' }}>
                        <input
                          type="radio"
                          name="options"
                          value={questions[currentQuestion].option3}
                          onChange={() => {
                            if (selectedAnswer === null) {
                              setSelectedAnswer(questions[currentQuestion].option3);
                              setIsCorrect(
                                questions[currentQuestion].option3.toLowerCase() ===
                                questions[currentQuestion].quizanswer.toLowerCase()
                              );
                            }
                          }}
                          disabled={selectedAnswer !== null}
                        />
                        <div dangerouslySetInnerHTML={{ __html: questions[currentQuestion].option3 }} />
                      </label>
                    )}

                    {questions[currentQuestion].option4 && (
                      <label className="p-2" style={{ width: '265px' }}>
                        <input
                          type="radio"
                          name="options"
                          value={questions[currentQuestion].option4}
                          onChange={() => {
                            if (selectedAnswer === null) {
                              setSelectedAnswer(questions[currentQuestion].option4);
                              setIsCorrect(
                                questions[currentQuestion].option4.toLowerCase() ===
                                questions[currentQuestion].quizanswer.toLowerCase()
                              );
                            }
                          }}
                          disabled={selectedAnswer !== null}
                        />
                        <div dangerouslySetInnerHTML={{ __html: questions[currentQuestion].option4 }} />
                      </label>
                    )}

                  </div>

                  <div className="ms-5 mt-4">
                    {selectedAnswer !== null ? (
                      isCorrect ? (
                        <p className="success">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="success-icon"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Correct answer!</span>
                        </p>
                      ) : (

                        <p className="danger">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="error-icon"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                          </svg>
                          <span style={{ marginLeft: "5px" }}>Wrong answer!</span>
                        </p>
                      )
                    ) : null}
                  </div>
                </>
              )}

              <div className="row text-center mt-1">
                <div className="col-md-6">
                </div>
                <div className="col-md-6"> <button className="ms-5 btn bg-gradiant2" onClick={nextQuestion}>Next Question</button></div>
              </div>



            </div>
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default Quizz;
