import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import useState from "react-hook-use-state";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo_new.png";
import Chat from '../chat';


function Header() {
    


    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        if (sidebar === true) {
            setSidebar(false);
        } else {
            setSidebar(true);
        }
    };

    const [sideba, setSideba] = useState(false);

    const showSideba = () => {
        if (sideba === true) {
            setSideba(false);
        } else {
            setSideba(true);
        }

    };



    const [navbar, setNavbar] = useState(false);

    const changeBg = () => {
        if (window.scrollY >= 100) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll', changeBg);


    const [showTopBtn, setShowTopBtn] = useState(true);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 0) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(true);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleClick = () => {
        setToggle(!toggle);
    };

    function testFunction() {
        goToTop();
        showSideba();
        handleClick();
    }
    const [toggle, setToggle] = useState(true)


    return (
        <>

            <body data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
                <Helmet>
                    <title> Omega InfoWay</title>
                </Helmet>


                <nav className={navbar ? 'navbar shadow navbar-expand-lg fixed-top navbar-custom sticky sticky-light bg-light' : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light bg-light'} id="navbar">

                    <div class="container">

                        {showTopBtn && (
                            <Link class="navbar-brand logo text-uppercase" to="/" onClick={goToTop} >

                                <img
                                    src={logo} class="logo-dark" alt="" height="60" width="245" />
                            </Link>
                        )}{" "}

                        <button className={sideba === false ? 'navbar-toggler collapsed' : 'navbar-toggler'} type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                            aria-controls="navbarCollapse" aria-expanded="false" onClick={showSidebar} aria-label="Toggle navigation" id="btm123">
                            <span className={sidebar === false ? 'mdi mdi-menu' : 'close'}></span>
                        </button>

                        <div class={sidebar === false ? 'navbar-collapse collapse' : 'navbar-collapse collapse show'} id="navbarCollapse">
                            <ul class="navbar-nav ms-auto" id="navbar-navlist">
                                <li class="nav-item" onClick={showSidebar}> {showTopBtn && (
                                    <Link class="nav-link" onClick={testFunction} to="/">Home</Link>
                                )}{" "} </li>

                                <li class="nav-item" onClick={showSidebar}>
                                    <Link class="nav-link" onClick={testFunction} to="services">Services</Link>
                                </li>
                                <li class="nav-item" onClick={showSidebar}>
                                    <Link class="nav-link" onClick={testFunction} to="/about">About</Link>
                                </li>
                                <li class="nav-item" onClick={showSidebar}>
                                    <Link class="nav-link" onClick={testFunction} to="/career">Career</Link>
                                </li>
                                <li class="nav-item" onClick={showSidebar}>
                                    <Link class="nav-link" onClick={testFunction} to="/quiz">Attempt quiz</Link>
                                </li>

                            </ul>
                            <div class="contact-btn" onClick={showSidebar}>
                                <Link className="btn bg-gradiant" style={{ marginRight: "0" }} onClick={testFunction} to="contact">Contact</Link>
                            </div>


                        </div>

                    </div>
                
                    <Chat/>
                </nav>



            </body>
        </>
    );
}

export default Header;