import React from "react";
import "../assets/css/about.css";
import { Helmet } from "react-helmet";

// api file
import * as Constant from "./Constant";
import Skeleton from "react-loading-skeleton";

class About extends React.Component {
  
  constructor() {
    super();
    this.state = {
      about: [],
      Loading:true,
    };
  }

  getTestimonial = async () => {
    await fetch(Constant.ShowAbout, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Loading:false,
            about: data.data
          });
          console.log('terms', this.state.about)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getTestimonial();
  }

render() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Omega InfoWay</title>
      </Helmet>
      <React.Fragment>
        <div class="container">
          <h2 class="global_title pt-5 mt-5 text-center">About Us</h2>
        
          { this.state.Loading === true ?
            <div class="row specing17">

           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bgg-dark">
             <h1><Skeleton width={350}/></h1>
             <p style={{ textAlign:'justify' }}><Skeleton count={4}/> </p>
           </div>
              

           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bgg-dark">
             <h1><Skeleton width={350}/></h1>
             <p style={{ textAlign:'justify' }}><Skeleton count={4}/> </p>
           </div>


           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bgg-dark">
             <h1><Skeleton width={350}/></h1>
             <p style={{ textAlign:'justify' }}><Skeleton count={4}/> </p>
           </div>
         </div>
          :        
             <div class="row specing17">
             {
                    this.state.about.map((item) => {
                      return ( 
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 bgg-dark">
              <h1>{item.title}</h1>
              <p style={{ textAlign:'justify' }}>{item.content} </p>
            </div>
               );
              })
            }
        
          </div>
       }
        </div>
      </React.Fragment>
    </>
  );
}
}

export default About;
