import React ,{useRef}from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function Chat() {

    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };
  return (
    <div>
       <div className="App">
            <button onClick={handleMinimize}></button>

            <TawkMessengerReact
                propertyId="64646d2374285f0ec46be973"
                widgetId="1h0k4kl5p"
                ref={tawkMessengerRef}/>
        </div>
    </div>
  )
}
