import React from "react";
import { Helmet } from "react-helmet";

// api file
import * as Constant from "./Constant";
import Skeleton from "react-loading-skeleton";

class PrivacyPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      privacy: [],
      Loading:true,
    };
  }


  getTestimonial = async () => {
    await fetch(Constant.ShowPrivacy, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Loading:false,
            privacy: data.data
          });
          console.log('privacy', this.state.privacy)
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getTestimonial();
  }

  render() {

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy Policy - Omega InfoWay</title>
        </Helmet>
        <React.Fragment>
          <section class="ftco-section">
            <div class="container">

              <div class="card card-career info-wrap w-100 pt-3 pb-3 mt-5">
                <div class="row justify-content-center mainheading">
                  <div class="col-md-6 text-center ">
                    <h2 class="heading-section mt-1">Privacy Policy</h2>
                  </div>
                </div>

                { this.state.Loading === true ? 

               <div className="container">
                  
                        <div className="row mt-4">
                          <p><Skeleton/></p>


                          <h4><Skeleton width={250} height={40} /></h4>
                          <p style={{textAlign:'justify', whiteSpace: "pre-line",lineHeight: "29px" }}><Skeleton count={4}/></p>
                       

                          <p><Skeleton/></p>

                          <h4><Skeleton width={250} height={40} /></h4>
                          <p style={{textAlign:'justify', whiteSpace: "pre-line",lineHeight: "29px" }}><Skeleton count={4}/></p>
                        </div>
                </div>


                  :

                <div className="container">
                  {
                    this.state.privacy.map((item) => {
                      return (
                        <div className="row mt-4">
                          <p>At Omega InfoWay, we value the trust you place in us when you visit one of our websites or use one of our web services.</p>


                          <h4>{item.title}</h4>
                          <p style={{textAlign:'justify', whiteSpace: "pre-line",lineHeight: "29px" }}>{item.content}</p>
                        </div>
                      );
                    })
                  }
                </div>

}
              </div>
            </div>
          </section>
        </React.Fragment>
      </>



    );
  }
}
export default PrivacyPolicy;