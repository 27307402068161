import React from "react";
import "../assets/css/services.css";
import img12 from "../assets/img/project.png";
import img13 from "../assets/img/worldwide.png";
import img14 from "../assets/img/country.png";
import img15 from "../assets/img/award.png";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";

// api file
import * as Constant from "./Constant";
import Skeleton from "react-loading-skeleton";

class Services extends React.Component {
  constructor() {
    super();
    this.state = {
      services: [],
      Loading:true,
    };
  }


  getTestimonial = async () => {
    await fetch(Constant.ShowServices, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Loading:false,
            services: data.data
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getTestimonial();
  }

  render() {

    return (
      <>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Service - Omega InfoWay</title>
        </Helmet>
        <React.Fragment>
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 text-center mb-5">
                  <h2 className="heading-section">Our services</h2>
                </div>
              </div>
              <h1 className="text-center fw-bold">
                We provide a wide range of<br></br>creative services
              </h1>

            { this.state.Loading === true ? 

<div class="row justify-content-center mt-5">
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
    
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
 
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
</div>

:

              <div className="row justify-content-center mt-5">
                {
                  this.state.services.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-4 wow fadeInUp mt-2">
                        <div className="service-item position-relative h-100">
                          <div
                            className="service-text rounded p-5 h-100"
                          >
                            <div className="mx-auto mb-1">

                              <img className="aboutimg" src={Constant.BaseUrlImage + item.image} alt="" />
                            </div>
                            <h4 className="mb-3">{item.title}</h4>
                            <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px'}}>  {item.content} </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>

              }
            </div>
          </section>

          <section className="ptb40 mt-5 mb-5 -5" id="countup">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} alt="" src={img12} />{" "}
                  </div>
                  <span className="counter" data-from="0" data-to="743">
                    <CountUp end={1730} duration={1} />+
                  </span>
                  <h4>Project Done</h4>
                </div>
                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "105px" }} alt="" src={img13} />{" "}
                  </div>
                  <span className="counter" data-from="0" data-to="579">
                    <CountUp end={125} duration={1} />M
                  </span>
                  <h4>User Worldwide</h4>
                </div>

                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} alt="" src={img14} />{" "}
                  </div>
                  <span className="counter" data-from="0" data-to="251">
                    <CountUp end={39} duration={1} />
                  </span>
                  <h4>Available Country</h4>
                </div>

                <div className="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} alt="" src={img15} />{" "}
                  </div>
                  <span className="counter" data-from="0" data-to="330">
                    <CountUp end={14} duration={1} />
                  </span>
                  <h4>Award Winner</h4>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      </>
    );
  }
}

export default Services;
