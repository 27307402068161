import { Route, Routes } from 'react-router-dom';
import './assets/css/colors/default.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.min.css';
import './assets/css/style.css';
import './assets/css/tiny-slider.css';
import './assets/css/materialdesignicons.min.css';
import './assets/css/fonts.css';
import './assets/carousel.min.css';
import './assets/footer/style.css';


import Header from './component/layout/Header';
import Home from './component/Home';
import Footer from './component/layout/Footer';
import Contact from './component/Contact';
import NoPage from './component/NoPage';
import About from './component/About';
import Career from './component/Career';
import Services from './component/Services';
import ThankYou from './component/thankyou/ThankYou';
import TermsConditions from './component/TermsConditions';
import PrivacyPolicy from './component/PrivacyPolicy';
import Chat from './component/chat';
import Attemptquiz from './component/Attemptquiz';
import Quiz from './component/Quiz';
import Quizquestion from './component/Quizquestion';
import Quizz from './component/Quizz';
import Quizthankyou from './component/thankyou/Quizthankyou';




function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="career" element={<Career />} />
          <Route path="thankyou" element={<ThankYou />} />
          <Route path="quizthankyou" element={<Quizthankyou />} />
          <Route path="termsconditions" element={<TermsConditions />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="attemptquiz/:id" element={<Attemptquiz />} />
          <Route path="quizquestion" element={<Quizquestion />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="Quizz/:id" element={<Quizz />} />
          <Route path="chat" element={<Chat />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
