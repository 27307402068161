import React from "react";
import LoadingOverlay from "react-loading-overlay";

// api file
import * as Constant from "./Constant";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      subject: "",
      textarea: "",

      contactaddress: "",
      contactphone: "",
      contactemail: "",
      contacttitle_linkdin:"",
      contactlinkdin:"",
      contactaddress_url:"",
    

      fullNameError: null,
      emailError: null,
      subjectError: null,
      textareaError: null,

      isLoading: false,
      Loading:true,
    };
  }

  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let subjectError = "";
    let textareaError = "";

    if (!this.state.fullName) {
      fullNameError = "Please Enter Full name.";
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Email Cannot Be Empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!this.state.subject) {
      subjectError = "Subject Cannot Be Empty, Please Enter Subject.";
    }

    if (!this.state.textarea) {
      textareaError = "Message Cannot Be Empty.";
    }

    if (fullNameError || emailError || subjectError || textareaError) {
      this.setState({
        fullNameError,
        emailError,
        subjectError,
        textareaError,
      });
      return false;
    }

    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        subjectError: null,
        textareaError: null,
      });
      this.onContact();
    }
  };

  onContact = async () => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("textarea", this.state.textarea);

    await fetch(Constant.ContactUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            fullName: "",
            email: "",
            subject: "",
            textarea: "",
          });
          window.location.href = Constant.BaseUrl + "thankyou";
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });


    };

    getSettings = async () => {
    
      await fetch(Constant.ShowCareerUrl, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            this.setState({ 
              Loading:false,
              contactaddress:data.data[0].address,
              contactaddress_url:data.data[0].address_url,
              contactemail:data.data[0].email,
              contactphone:data.data[0].phone,
              contacttitle_linkdin:data.data[0].title_linkdin	,
              contactlinkdin:data.data[0].linkdin,
            });
          }
        })
        .catch((error) => {
          if (error.message === "Failed to fetch") {
          } else {
            console.error("Error:!!!!", error);
          }
        });
    };


  componentDidMount(){
     this.getSettings();
     }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        fadeSpeed={10}
        text="Message Sending..."
      >
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us - Omega InfoWay</title>
          </Helmet>
          <React.Fragment>
            <section class="ftco-section">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-6 text-center mb-5">
                    <h2 class="heading-section">Contact Us</h2>
                  </div>
                </div>
                <div class="row07 justify-content-center">
                  <div class="col-lg-10 col-md-12">
                    <div class="wrapper">
                      <div class="row no-gutters">
                        <div class="col-md-7 d-flex align-items-stretch">
                          <div class="contact-wrap w-100 p-md-5 p-4">
                            <h3 class="mb-4">Get in touch</h3>
                            <div id="form-message-warning" class="mb-4"></div>

                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="fw-bold">Name:</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="fullName"
                                    placeholder="Name"
                                    onChange={(event) =>
                                      this.setState({
                                        fullName: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.fullNameError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.fullNameError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="fw-bold">Email:</label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.emailError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.emailError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="fw-bold">Subject:</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="subject"
                                    id="subject"
                                    placeholder="Subject"
                                    onChange={(event) =>
                                      this.setState({
                                        subject: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.subjectError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.subjectError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="fw-bold">Message:</label>
                                  <textarea
                                    name="textarea"
                                    class="form-control"
                                    cols="30"
                                    rows="7"
                                    placeholder="Message"
                                    onChange={(event) =>
                                      this.setState({
                                        textarea: event.target.value,
                                      })
                                    }
                                    required
                                  ></textarea>{" "}
                                  {!!this.state.textareaError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.textareaError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <button
                                    type="submit"
                                    class="btn bg-gradiant"
                                    onClick={() => this.onSubmit()}
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-5 d-flex align-items-stretch">
                        { this.state.Loading === true ? 

                        <div class="info-wrap bg-primary w-100 p-lg-5 p-4">
                            <h3 class="mb-4 mt-md-4">Contact us</h3>
                            <div class="dbox w-100 d-flex align-items-start">
                              <div class="text-07 pl-3">
                                <p>
                                  <Skeleton width={200} count={2}/>
                                </p>
                              </div>
                            </div>

                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="text-07 pl-3">
                                <p>
                                 
                                  <a class="text-white" href="tel://9909009304">
                                    <Skeleton width={200}/>
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="text-07 pl-3">
                                <p>
                                
                                  <a
                                    class="text-white"
                                    href="mailto:info@omegawebsolution.in"
                                  >
                                    <Skeleton width={200}/>
                                  </a>
                                </p>
                              </div>
                            </div>

                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="text-07 pl-3">
                                <p>
                                  
                                  <a
                                    class="text-white"
                                   
                                  > <Skeleton width={200}/>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>

                          :
                          <div class="info-wrap bg-primary w-100 p-lg-5 p-4">
                            <h3 class="mb-4 mt-md-4">Contact us</h3>
                            <div class="dbox w-100 d-flex align-items-start">
                              <div class="iconn d-flex align-items-center justify-content-center">
                                <span class="fa fa-map-marker"></span>
                              </div>
                              <div class="text-07 pl-3">
                                <p>
                                  <span>Address:</span> 
                                  <a class="text-white" target="_blank"  style={{ cursor: "pointer" }} href={this.state.contactaddress_url}>
                                  {this.state.contactaddress}
                                  </a>
                                  
                                </p>
                              </div>
                            </div>

                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="icon d-flex align-items-center justify-content-center">
                                <span class="fa fa-phone"></span>
                              </div>
                              <div class="text-07 pl-3">
                                <p>
                                  <span>Phone:</span>{" "}
                                  <a class="text-white" href={"tel://" + this.state.contactphone}>
                                  +91   {this.state.contactphone}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="icon d-flex align-items-center justify-content-center">
                                <span class="fa fa-paper-plane"></span>
                              </div>
                              <div class="text-07 pl-3">
                                <p>
                                  <span>Email:</span>{" "}
                                  <a
                                    class="text-white" 
                                    href={"mailto:" + this.state.contactemail}
                                  >
                                    {this.state.contactemail}
                                  </a>
                                </p>
                              </div>
                            </div>

                            <div class="dbox w-100 d-flex align-items-center">
                              <div class="icon d-flex align-items-center justify-content-center">
                                <span class="fa fa-linkedin-in"></span>
                              </div>
                              <div class="text-07 pl-3">
                                <p>
                                  <span>Linkedin:</span>{" "}
                                  <a
                                    class="text-white" target="_blank" 
                                    href={this.state.contactlinkdin}
                                  > {this.state.contacttitle_linkdin}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          }


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        </>
      </LoadingOverlay>
    );
  }
}

export default Contact;
