import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Quiz.css";
import { Link } from "react-router-dom";
import * as Constant from "./Constant";

function Quiz() {

  const [quiz, setJournal] = useState([]);
  useEffect(() => {
    getQuiz();
  },);

  const getQuiz = async () => {
    const response = await fetch(Constant.ShowQuiz);
    const data = await response.json();
    setJournal(data.data);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attempt Quiz - Omega InfoWay</title>
      </Helmet>
      <React.Fragment>
        <section class="ftco-section">
          <div class="container justify-content-center">
            <div className="row ms-3">
            { quiz.map((item) => {
             return (
           <div className="col-md-4">
            <article class="card cardquiz mt-5">
              <img
                className="card__background"
                src={Constant.BaseUrlImage +  item.image}
                alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                width="1920"
                height="2193"
              />
              <div class="card__content | flow">
                <div class="card__content--container | flow">
                  <h2 class="card__title h22">{item.title}</h2>
                  <p class="card__description pragefg">
                  {item.content}
                  </p>
                </div>
                <Link  to={(`/attemptquiz/${item.id}`)} class="card__button">Attempt Quiz</Link>
              </div>
            </article>
            </div>
 
 )
            })
          }
          
            </div>
          </div>
        </section>
      </React.Fragment>
    </>
  );
}

export default Quiz;
