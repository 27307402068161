import React from "react";
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from "react-router-dom";

// api file
import * as Constant from "../Constant";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {

      contactaddress: "",
      contactphone: "",
      contactemail: "",
    };
  }


  getSettings = async () => {

    await fetch(Constant.ShowCareerUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            contactaddress: data.data[0].address,
            contactemail: data.data[0].email,
            contactphone: data.data[0].phone
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };


  componentDidMount() {
    this.getSettings();
  }

  render() {
    return (
      <>
        <React.Fragment>


          <div class="footer">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-sm-4 col-xs-12">
                  <div class="single_footer single_footer_address">
                    <h4>Services</h4>
                    <ul>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">Web development</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">Interface Design</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">Business Consulting </Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">Branding</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">App development</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/Services">Content creation</Link></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="single_footer single_footer_address">
                    <h4>Quick Links</h4>
                    <ul>
                      <li><IoIosArrowForward />&nbsp;<Link to="/termsconditions">Terms & Conditions</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/privacypolicy">Privacy Policy</Link></li>
                      <li><IoIosArrowForward />&nbsp;<Link to="/about">About</Link></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="single_footer single_footer_address">
                    <h4>Contact Us</h4>
                    <div class="signup_form">
                      <form action="#" class="subscribe d-flex">
                        <div class="footer-icon">
                          <span class="fa fa-phone ms-3 mt-3"></span>
                        </div>
                        <a class="footesize mx-4 mt-3" href={"tel://" + this.state.contactphone} style={{ color: '#fff' }}>+91 {this.state.contactphone}</a>
                      </form>

                      <form action="#" class="d-flex">
                        <div class="footer-icon">
                          <span class="fa fa-paper-plane ms-3 mt-3"></span>
                        </div>
                        <a class="footesize mx-4 mt-3" href={"mailto:" + this.state.contactemail} style={{ color: '#fff' }}>{this.state.contactemail}</a>
                      </form>
                    </div>
                  </div>


                  <div class="logonew footerleft">
                    <ul class="">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100073944702597&mibextid=ZbWKwL" target="_blank" >
                          <i class="fab fa-facebook-f icon"></i>    </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/omega_infoway?igshid=ZDdkNTZiNTM=" target="_blank" ><i class="fab fa-instagram icon"></i></a>
                      </li>
                      <li>
                        <a href="https://in.linkedin.com/company/omega-infoway" target="_blank" ><i class="fab fa-linkedin-in icon"></i></a></li>
                      <li>
                        <a href="mailto:hr@omegainfoway.com"><i class="fab fa-google-plus-g icon"></i></a></li>
                    </ul>

                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <p class="copyright">Copyright © 2023 <a href="http://omegainfoway.com/" style={{ fontWeight: 'bold', fontSize: '20px' }}>Omega InfoWay</a>.</p>
                </div>
              </div>
            </div>
          </div>


        </React.Fragment>

      </>
    );
  }
}

export default Footer;
