// QuizQuestion.js

import React from "react";
import { useState } from "react";

const Quizquestion = ({ question, updateTotalScore, totalScore, isAnswered }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);

  const handleAnswerSelection = (selectedOption) => {
    if (!isAnswered) {
      setSelectedAnswer(selectedOption);
      const isAnswerCorrect =
        selectedOption.toLowerCase() === question.quizanswer.toLowerCase();

      setIsCorrect(isAnswerCorrect);

      // Update the total score if the answer is correct
      if (isAnswerCorrect) {
        updateTotalScore(totalScore + 1);
      }
    }
  };

  console.log("Selected Answer:", selectedAnswer);
  console.log("Correct Answer:", question.quizanswer);
  console.log("Is Correct?", isCorrect);

  return (
    <>
      <div className="text-center">
       <h1><div dangerouslySetInnerHTML={{ __html: question.question }} /></h1> 
       
        <label className="p-2">
          <input
            type="radio"
            name="options"
            value={question.option1}
            onChange={() => {
              setSelectedAnswer(question.option1);
              setIsCorrect(
                question.option1.toLowerCase() ===
                question.quizanswer.toLowerCase()
              );
            }}
          />

          <div dangerouslySetInnerHTML={{ __html: question.option1 }} />
        </label>
        <label className="p-2">
          <input
            type="radio"
            name="options"
            value={question.option2}
            onChange={() => {
              setSelectedAnswer(question.option2);
              setIsCorrect(
                question.option2.toLowerCase() ===
                question.quizanswer.toLowerCase()
              );
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: question.option2 }} />
        </label>
        <br />
        <label className="p-2">
          <input
            type="radio"
            name="options"
            value={question.option3}
            onChange={() => {
              setSelectedAnswer(question.option3);
              setIsCorrect(
                question.option3.toLowerCase() ===
                question.quizanswer.toLowerCase()
              );
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: question.option3 }} />
        </label>
        <label className="p-2">
          <input
            type="radio"
            name="options"
            value={question.option4}
            onChange={() => {
              setSelectedAnswer(question.option4);
              setIsCorrect(
                question.option4.toLowerCase() ===
                question.quizanswer.toLowerCase()
              );
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: question.option4 }} />
        </label>
        <div dangerouslySetInnerHTML={{ __html: question.quizanswer }} />
      </div>

      <div className="ms-5">
        {selectedAnswer !== null ? (
          isCorrect ? (
            <p className="success">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                className="success-icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span style={{ marginLeft: "5px" }}>Correct answer!</span>
            </p>
          ) : (

            <p className="danger">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                className="error-icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
              </svg>
              <span style={{ marginLeft: "5px" }}>Wrong answer!</span>
            </p>
          )
        ) : null}
      </div>
    </>
  );
};

export default Quizquestion;
