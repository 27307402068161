import React from "react";
import LoadingOverlay from "react-loading-overlay";

// api file
import * as Constant from "./Constant";
import { Helmet } from "react-helmet";

class Career extends React.Component {
  constructor() {
    super();
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      position: "",
      resume: "",
      reference: "",

      Position: [],
      firstnameError: null,
      lastnameError: null,
      emailError: null,
      phoneError: null,
      positionError: null,
      fileError: null,

      isLoading: false,
    };
  }

  validate = () => {
    let firstnameError = "";
    let lastnameError = "";
    let emailError = "";
    let phoneError = "";
    let positionError = "";
    let fileError = "";

    if (!this.state.firstname) {
      firstnameError = "Please Enter First Name.";
    }
    if (!this.state.lastname) {
      lastnameError = "Please Enter Last Name.";
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Email Cannot Be Empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!this.state.phone) {
      phoneError = "Phone Cannot Be Empty.";
    }
    if (!this.state.position) {
      positionError = "Position Cannot Be Empty.";
    }
    if (!this.state.resume) {
      fileError = "Upload Cannot Be Empty.";
    }

    console.log("firstname", this.state.firstname);
    console.log("lastname", this.state.lastname);
    console.log("email", this.state.email);
    console.log("phone", this.state.phone);
    console.log("position", this.state.position);
    console.log("resume", this.state.resume);

    if (
      firstnameError ||
      lastnameError ||
      emailError ||
      phoneError ||
      positionError ||
      fileError
    ) {
      this.setState({
        firstnameError,
        lastnameError,
        emailError,
        phoneError,
        positionError,
        fileError,
      });
      return false;
    }

    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        firstnameError: null,
        lastnameError: null,
        emailError: null,
        phoneError: null,
        positionError: null,
        fileError: null,
      });
      this.onContact();
    }
  };

  onContact = async () => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("firstname", this.state.firstname);
    formData.append("lastname", this.state.lastname);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("position", this.state.position);
    formData.append("resume", this.state.resume);
    formData.append("reference", this.state.reference);
    console.log("---------------------", this.state.resume);
    await fetch(Constant.CareerUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            position: "",
            resume: "",
            reference: "",
          });
          window.location.href = Constant.BaseUrl + "thankyou";
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });
  };

  getPosition = async () => {
    await fetch(Constant.ShowPosition, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Position: data.data,
          });
          console.log("data", this.state.Position);
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  handleChangePhone = (event) => {
    const result = event.target.value.replace(/[^0-9]/gi, "");
    this.setState({ phone: result });
  };

  componentDidMount() {
    this.getPosition();
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        fadeSpeed={10}
        text="Sending..."
      >
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Career - Omega InfoWay</title>
          </Helmet>
          <React.Fragment>
            <section class="ftco-section">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-6 text-center mb-5">
                    <h2 class="heading-section">Career With Us</h2>
                  </div>
                </div>
                <div class="card card-career info-wrap w-100 p-lg-5 p-4">
                  <div class="row justify-content-center">
                    <div class="col-md-10">
                      <div class="row justify-content-around text-center mt-4">
                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">First Name:</label>
                            <input
                              type="text"
                              class="form-control form-control2 fw-bold"
                              placeholder="Enter First Name"
                              onChange={(event) =>
                                this.setState({
                                  firstname: event.target.value,
                                })
                              }
                              required
                            />{" "}
                            {!!this.state.firstnameError && (
                              <div style={{ color: "red", textAlign: "start" }}>
                                {this.state.firstnameError}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">Last Name:</label>
                            <input
                              type="text"
                              class="form-control form-control2 fw-bold"
                              placeholder="Enter Last Name"
                              onChange={(event) =>
                                this.setState({
                                  lastname: event.target.value,
                                })
                              }
                              required
                            />{" "}
                            {!!this.state.lastnameError && (
                              <div style={{ color: "red", textAlign: "start" }}>
                                {this.state.lastnameError}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-around text-center mt-xl-3 mt-0">
                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">E-mail:</label>
                            <input
                              type="email"
                              class="form-control form-control2 fw-bold"
                              placeholder="Enter Email"
                              onChange={(event) =>
                                this.setState({
                                  email: event.target.value,
                                })
                              }
                              required
                            />{" "}
                            {!!this.state.emailError && (
                              <div style={{ color: "red", textAlign: "start" }}>
                                {this.state.emailError}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">Phone Number:</label>
                            <input
                              type="text"
                              autoComplete="on"
                              value={this.state.phone}
                              onChange={this.handleChangePhone}
                              placeholder="Enter Phone Number"
                              id="phone"
                              minLength="10"
                              maxLength="10"
                              class="form-control form-control2 fw-bold"
                              required
                            />
                            {!!this.state.phoneError && (
                              <div style={{ color: "red", textAlign: "start" }}>
                                {this.state.phoneError}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-around text-center mt-xl-3 mt-0">
                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">Applied Position:</label>

                            <div className="mt-1">
                              <select
                                class="form-select-lg form-control form-control2 fw-bold"
                                onChange={(event) =>
                                  this.setState({
                                    position: event.target.value,
                                  })
                                }
                                required
                              >
                                <option selected> Select Position</option>
                                {this.state.Position.map((obj) => {
                                  return (
                                    <option value={obj.id}>
                                      {" "}
                                      {obj.position}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {!!this.state.positionError && (
                              <div style={{ color: "red", textAlign: "start" }}>
                                {this.state.positionError}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">Upload Resume!</label>
                            <div class="text-start">
                              <input
                                type="file"
                                id="resume"
                                name="resume"
                                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                onChange={(event) =>
                                  this.setState({
                                    resume: event.target.files[0],
                                  })
                                }
                                required
                              />{" "}
                              {!!this.state.fileError && (
                                <div
                                  style={{ color: "red", textAlign: "start" }}
                                >
                                  {this.state.fileError}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row justify-content-around text-center mt-xl-3 mt-0">
                        <div class="col-md-4">
                          <div class="form-group text-start">
                            <label class="fw-bold">Reference:</label>
                            <input
                              type="text"
                              class="form-control form-control2 fw-bold"
                              placeholder="Enter Reference"
                              onChange={(event) =>
                                this.setState({
                                  reference: event.target.value,
                                })
                              }
                              required
                            />{" "}
                          </div>
                        </div>
                        <div class="col-md-4">
                          </div>
                        </div>

                        <div class="mt-5 pb-4 justify-content-around text-center mt-xl-3">
                          <button
                            type="submit"
                            class="btn bg-gradiant"
                            onClick={() => this.onSubmit()}
                          >
                            Submit Application
                          </button>
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        </>
      </LoadingOverlay>
    );
  }
}
export default Career;
