import React from "react";
import svgimage from "../assets/img/main_section.jpg";
import Carousel1 from 'react-bootstrap/Carousel';
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import img7 from "../assets/img/user3.jpg";
import img12 from "../assets/img/project.png";
import img13 from "../assets/img/worldwide.png";
import img14 from "../assets/img/country.png";
import img15 from "../assets/img/award.png";

import { Helmet } from "react-helmet";

import CountUp from "react-countup";

// api file
import * as Constant from "./Constant";
import { DynamicStar } from "react-dynamic-star";
import Skeleton from "react-loading-skeleton";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "",
      text: "",
      star: "",
      user: [],
      testimonial: [],
      services: [],
      IsLoading:true,
    };
  }



  getTestimonial = async () => {
    await fetch(Constant.ShowTestimonials, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.success === true) {
          this.setState({
            IsLoading:false,
            testimonial: data.data
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTestimonial2 = async () => {
    
    await fetch(Constant.ShowServices, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            IsLoading:false,
            services: data.data
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };


  componentDidMount() {
    this.getTestimonial();
    this.getTestimonial2();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home - Omega Infoway</title>
        </Helmet>
        <React.Fragment>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6 mt-5">
                <div class="home1">
                  <div className="text">
                    <h1>We Provide many IT & Technology Services</h1>
                    <p>
                      Omega Infoway foundation is built-up on imparting a
                      complete IT solution to the companies and individuals in
                      need. The core aim of our customer’s satisfaction, is the
                      key that keeps us encouraged and going, to proffer the
                      beneficial result to our clients. Our area of our
                      excellence ranges from web development to hiring a web
                      designer, mobile app development, e-commerce development,
                      iOS development and word press development.
                    </p>
                    <div class="home-links">
                      <Link class="btn bg-gradiant" to="/Services">
                        Our Services
                      </Link>
                      <Link class="btn bg-gradiant" to="/contact">
                        Contact Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="image1">
                  <img className="img-fluid imgowg" src={svgimage} alt="home-image" />
                </div>
              </div>
            </div>
          </div>

          <section class="ftco-section">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 text-center mb-5">
                  <h2 class="heading-section">Our services</h2>
                </div>
              </div>
              <h1 class="text-center fw-bold">
                We provide a wide range of<br></br>creative services
              </h1>
            { this.state.IsLoading === true ? 
 <div class="row justify-content-center mt-5">
       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <img className="aboutimg" alt="" />
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>
        
       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <img className="aboutimg" alt="" />
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>

       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <img className="aboutimg" alt="" />
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>

       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <img className="aboutimg" alt="" />
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>

       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>

       <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
         <div class="service-item position-relative h-100">
           <div
             class="service-text rounded p-5 h-100 shadow">
             <div class="mx-auto mb-4">
               <img className="aboutimg" alt="" />
               <Skeleton  className="aboutimg" width={90}  height={60}/>
             </div>
             <h4 className="mb-3"><Skeleton width={190} /> </h4>
             <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
           </div>
         </div>
       </div>
</div>

:
              <div class="row justify-content-center mt-5">
                {
                  this.state.services.map((item) => {
                    return (
                      <div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
                        <div class="service-item position-relative h-100">
                          <div
                            class="service-text rounded p-5 h-100 shadow">
                            <div class="mx-auto mb-4">
                              <img className="aboutimg" src={Constant.BaseUrlImage + item.image} alt="" />
                            </div>
                            <h4 className="mb-3">{item.title}</h4>
                            <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}>  {item.content} </p>
                          </div>
                        </div>
                      </div>

                    );
                  })
                }
              </div>
}
            </div>
          </section>



          <section class="section bg-light testimonial" id="testimonial">
            <div class="container">
              <div class="row align-items-cente">
                <div class="col-lg-3">
                  <div class="title">
                    <p class=" text-primary fw-bold mb-2">
                      Client Testimonial{" "}
                      <i class="mdi mdi-cellphone-iphone"></i>
                    </p>
                    <h3>What they think of us!</h3>
                    <p class="text-muted para-width f-15 mt-3 mb-0"></p>
                  </div>
                </div>

              {  this.state.IsLoading === true ? 

<div class="col-lg-9">
<div class="tns-outer" id="testi-slider-ow">
  <div id="testi-slider-mw" class="tns-ovh">
    <div class="tns-inner" id="testi-slider-iw">
      <div
        class="testi-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal mystyle"
        id="testi-slider"
      >

        <Carousel1>

                 {/* <Carousel.Item interval={1000} infiniteLoop touch={true}> */}

                <Carousel1.Item>
                  <div class="item">
                    <div class="position-relative overflow-hidden">

                      <div class="row align-items-start">
                        <Carousel1.Caption>
                          
                          <div class="media mohit d-flex">
                             <Skeleton class="mr-3" borderRadius={100} width={100} height={100}/>
                            <div class="media-body">

                              <div class="testimonial">
                                <p class="overview text-dark"><b><Skeleton width={100} height={30}/></b></p>
                                <p class="text-wrap text-testi text-dark" style={{ textAlign:'justify' }}> <Skeleton width={400} count={3}/></p>
                                <p class="overview">
                                </p>

                              </div>


                            </div>
                          </div>

                         
                        </Carousel1.Caption>
                      </div>
                    </div>
                  </div>

                </Carousel1.Item>
          </Carousel1>
      </div>
    </div>
  </div>
</div>
</div>

              :

                <div class="col-lg-9">
                  <div class="tns-outer" id="testi-slider-ow">
                    <div id="testi-slider-mw" class="tns-ovh">
                      <div class="tns-inner" id="testi-slider-iw">
                        <div
                          class="testi-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal mystyle"
                          id="testi-slider"
                        >

                          <Carousel1>
                            {
                              this.state.testimonial.map((item) => {
                                return (

                                  // <Carousel.Item interval={1000} infiniteLoop touch={true}>

                                  <Carousel1.Item>
                                    <div class="item">
                                      <div class="position-relative overflow-hidden">

                                        <div class="row align-items-start">
                                          <Carousel1.Caption>
                                            
                                            <div class="media mohit d-flex">
                                              <img src={img7} class="mr-3" alt="" />
                                              <div class="media-body">

                                                <div class="testimonial">
                                                  <p class="overview text-dark"><b>{item.title}</b></p>
                                                  <p class="text-wrap text-testi text-dark" style={{ textAlign:'justify' }}> {item.text}</p>
                                                  <p class="overview">
                                                    <DynamicStar
                                                      fullStarColor={"#255F51"}
                                                      height={25}
                                                      width={25}
                                                      rating={item.star}
                                                    />
                                                  </p>

                                                </div>


                                              </div>
                                            </div>

                                           
                                          </Carousel1.Caption>
                                        </div>
                                      </div>
                                    </div>

                                  </Carousel1.Item>
                                );
                              })
                            }</Carousel1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
}
              </div>
            </div>
          </section>



          <div class="container displ">
            <div class="row">
              <div class="col-md-12">
                <div id="testimonial-slider" class="owl-carousel">
                  <Carousel autoPlay interval="2100" infiniteLoop emulateTouch>
                    {
                      this.state.testimonial.map((item) => {
                        return (
                          <div class="testimonial2">

                            <div class="pic">
                              <img src={img7} />
                            </div>
                            <h3 class="title">{item.title}</h3>

                            <p class="description2">
                              {item.text}
                            </p>

                            <DynamicStar
                              fullStarColor={"#255F51"}
                              height={25}
                              width={25}
                              rating={item.star}
                            />


                          </div>
                        );

                      })
                    }
                  </Carousel>
                </div>
              </div>
            </div>
          </div>



          <section class="ptb40 mt-5 mb-5 -5" id="countup">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} src={img12} />{" "}
                  </div>
                  <span class="counter" data-from="0" data-to="743">
                    <CountUp end={1730} duration={1} />+
                  </span>
                  <h4>Project Done</h4>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "105px" }} src={img13} />{" "}
                  </div>
                  <span class="counter" data-from="0" data-to="579">
                    <CountUp end={125} duration={1} />M
                  </span>
                  <h4>User Worldwide</h4>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} src={img14} />{" "}
                  </div>
                  <span class="counter" data-from="0" data-to="251">
                    <CountUp end={39} duration={1} />
                  </span>
                  <h4>Available Country</h4>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <div>
                    <img style={{ height: "100px" }} src={img15} />{" "}
                  </div>
                  <span class="counter" data-from="0" data-to="330">
                    <CountUp end={14} duration={1} />
                  </span>
                  <h4>Award Winner</h4>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>

      </>
    );
  }
}

export default Home;

