import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
// import { Link } from "react-router-dom";
import * as Constant from "./Constant";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class Attemptquiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      q_id: "",

      nameError: null,
      emailError: null,
      numberError: null, 
      isLoading: false,
    };
    //  var id = window.location.pathname.split('/')[2];
  }

  validate = () => {
    let nameError = "";
    let emailError = "";
    let numberError = "";

    if (!this.state.name) {
      nameError = "Please Enter FullName.";
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please Enter Email.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!this.state.number) {
      numberError = "Please Enter Phone Number.";
    }

    if (nameError || emailError || numberError) {
      this.setState({
        nameError,
        emailError,
        numberError,
      });
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        nameError: null,
        emailError: null,
        numberError: null,
      });
      this.onContact();
    }
  };

  onContact = async () => {

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("number", this.state.number);
    var q_id = window.location.pathname.split('/')[2];
    formData.append("q_id", q_id);

    localStorage.setItem("email", this.state.email);
   
    await fetch(Constant.QuizeUser, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            q_id: "",
            name: "",
            email: "",
            number: "",
          })
          var id = window.location.pathname.split('/')[2];
            window.location.href = "/Quizz/" + id;
        } else {
          this.setState({ isLoading: false });
          toast.error(data.message, {
            theme: "dark",
          });
        }
      });
  };

  handleChangePhone = (event) => {
    const result = event.target.value.replace(/[^0-9]/gi, "");
    this.setState({ number: result });
  };


  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Attempt Quiz - Omega InfoWay</title>
        </Helmet>
        <React.Fragment>
          <section class="ftco-section">
            <div class="container d-flex justify-content-center">
              <div class="card card-career info-wrap w-60 pt-3 pb-3 mt-5">
                <div class="row justify-content-center mainheading" style={{     borderRadius:'10px' }}>
                  <div class="col-md-6 text-center ">
                    <h2 class="heading-section mt-1">Attempt Quiz</h2>
                  </div>
                </div>
                <div className="d-flex" style={{ minWidth: "max-content" }}>
                <ToastContainer
                  rtl="false"
                  style={{ height: "300px", width: "300px" }}
                />
              </div>
            
                  <div className="row mt-4">
                   
                      <div class="row d-flex justify-content-center">
                        <div class="col-md-10 d-flex align-items-stretch">
                        
                            <div class="row justify-content-center">
                              <div class="col-md-8">
                                <div class="form-group">
                                  <label class="fw-bold">Name:</label>
                                  <input
                                    type="text"
                                    class="form-control form-control2 fw-bold"
                                    name="fullName"
                                    placeholder="Name"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.nameError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.nameError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-8">
                                <div class="form-group">
                                  <label class="fw-bold">Email:</label>
                                  <input
                                    type="email"
                                    class="form-control form-control2 fw-bold"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.emailError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.emailError}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="form-group">
                                  <label class="fw-bold">Number:</label>
                                  <input
                                  type="text"
                                  autoComplete="on"
                                  value={this.state.number}
                                  onChange={this.handleChangePhone}
                                  placeholder="Number"
                                  id="phone"
                                  minLength="10"
                                  maxLength="10"
                                  class="form-control form-control2 fw-bold"
                                  required
                                />

                                  {!!this.state.numberError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.numberError}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div class="col-md-12 d-flex justify-content-center">
                                <div class="form-group">
                                  <button
                                    type="submit"
                                    class="btn bg-gradiant" 
                                    to='quizquestion'
                                    onClick={() => this.onSubmit()}
                                  >
                                    Start Quiz
                                  </button> 
                                  {/* <Link  to='/Quizz'>Start Quiz</Link> */}
                                </div>
                              </div>

                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
        
          </section>
        </React.Fragment>
      </>
    );
  }
}

export default Attemptquiz;
