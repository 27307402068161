export const BaseUrlApi = "https://admin.omegainfoway.com/api/"
export const BaseUrlImage = "https://admin.omegainfoway.com/public/"
// export const BaseUrlImage = "http://localhost/omega_web/public/"
// export const BaseUrlApi = "http://localhost/omega_web/api/"

export const ContactUrl = BaseUrlApi + 'contact'
export const CareerUrl = BaseUrlApi + 'career'
export const ShowCareerUrl = BaseUrlApi + 'show_contact'
export const ShowTestimonials = BaseUrlApi + 'show_testimonial'
export const ShowTerms = BaseUrlApi + 'terms'
export const ShowPrivacy = BaseUrlApi + 'privacy'
export const ShowAbout = BaseUrlApi + 'about'
export const ShowServices = BaseUrlApi + 'services'
export const ShowPosition = BaseUrlApi + 'position'
export const ShowQuiz = BaseUrlApi + 'quiz'
export const QuizeUser = BaseUrlApi + 'quiz_user_register'
export const QuizeUserUpdate = BaseUrlApi + 'quiz_user_update'
export const QuizQuestion = BaseUrlApi + 'quizquestion'

export const BaseUrl = "https://www.omegainfoway.com/"

